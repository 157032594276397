import { Box } from "@chakra-ui/react";
import { matchPath, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import FloatingSocialMedia from "./components/floating/FloatingSocialMedia";
import Footer from "./components/footer/Footer";
import Navbar from "./components/navbar/Navbar";
import {
  AdmitcardsPage,
  AdvertisePage,
  ContactUsPage,
  DetailsPage,
  HomePage,
  JobsPage,
  NotfoundPage,
  PolicyPage,
  ResultsPage,
  StateJobsPage,
} from "./routes/elements";

function App() {
  const location = useLocation();

  // Define the routes where Navbar and Footer should appear
  const pathsWithNavbarAndFooter = [
    "/",
    "/latest-job",
    "/contact-Us",
    "/advertise",
    "/results",
    "/admitcards",
    "/policy",
  ];

  // Check if the current path matches any of the predefined paths or the job-details route with an id
  const isNotFoundPage = !(
    pathsWithNavbarAndFooter.includes(location.pathname) ||
    matchPath("/job-details/:id", location.pathname) ||
    matchPath("/state-jobs/:state", location.pathname) ||
    matchPath("/contact-Us", location.pathname)
  );

  return (
    <Box background={"#f7f8f9"}>
      <Box
        width={{ lg: "70%", md: "80%", base: "100%" }}
        height="100%"
        margin="auto"
        background="white"
      >
        {!isNotFoundPage && <Navbar />}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/latest-job" element={<JobsPage />} />
          <Route path="/state-jobs/:state" element={<StateJobsPage />} />
          <Route path="/job-details/:id" element={<DetailsPage />} />
          <Route path="/contact-Us" element={<ContactUsPage />} />
          <Route path="/advertise" element={<AdvertisePage />} />
          <Route path="/results" element={<ResultsPage />} />
          <Route path="/admitcards" element={<AdmitcardsPage />} />
          <Route path="/policy" element={<PolicyPage />} />
          <Route path="*" element={<NotfoundPage />} />
        </Routes>
        {!isNotFoundPage && <FloatingSocialMedia />}
        {!isNotFoundPage && <Footer />}
      </Box>
    </Box>
  );
}

export default App;
