import {
  Box,
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { IoMdSearch } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { SearchJobs } from "../../redux/jobs/job.action";
import Card from "../Homecard/Card";

const Search = () => {
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { Search } = useSelector((state) => state.JobReducer);

  const handleSearch = () => {
    if (search.length > 3) {
      onOpen();
      dispatch(SearchJobs({ search: search }));
      // Clear the search input field after dispatching the search action
      setSearch("");
    } else {
      toast({
        title: "Search must be greater than 3 characters",
        status: "warning",
        duration: 2 * 1000,
        isClosable: true,
      });
    }
  };

  return (
    <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
      <Flex alignItems={"Center"}>
        <Input
          color={"black"}
          type="text"
          placeholder="Search Jobs, Results etc."
          borderRadius={"20px 0 0 20px"}
          border={"1px solid gray"}
          p={"0 15px"}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault(); // Prevent default form submission
              handleSearch();
            }
          }}
        />
        <Button
          borderRadius={"0 20px 20px 0"}
          border={"1px solid grey"}
          onClick={handleSearch}
        >
          <IoMdSearch fontSize={"25px"} />
        </Button>
      </Flex>

      <Modal
        size={{ base: "sm", md: "lg", lg: "xl" }}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Search Result: {Search?.total}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {Search?.posts?.map((elem) => (
              <Card
                key={elem?._id}
                {...elem}
                onClose={onClose}
                searchResult={true}
              />
            ))}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Search;
